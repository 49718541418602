.side-bar {
  transform: translate3d(0, 0, 0);
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  position: fixed;

  .hover-scroll-nav {
    background-color: #fff;

    > ul {
      position: relative;
      top: 150px;
    }
  }

  li {
    border-left: 4px solid $ui-gray;

    &.completed {
      border-color: $completed-state-color;
    }
  }

  a {
    color: $text-gray;
    cursor: pointer;

    &.active {
      color: $text-active-gray;
      background-color: $ui-gray;
    }
  }
}
